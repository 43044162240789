import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Logo from '@/public/images/logoanft.png';
import { socialIcons } from '@/sections/footer/config';
import { getUser } from '@/requests/queries/getUser';
import useFirebaseAuth from '@/shared/hooks/useFirebaseAuth';
import { useQuery } from 'react-query';
import { User } from 'firebase/auth';

export const Footer = () => {
    const user = useFirebaseAuth();

    const { data: userData } = useQuery({
        queryKey: ['userData', user],
        queryFn: () => getUser((user as User).uid),
        staleTime: 0,
        enabled: !!user?.uid,
    });

    console.log(userData);

    const footerMenuList = [
        {
            id: 1,
            title: 'Marketplace',
            diffClass: '',
            hidden: !user?.uid,
            list: [
                {
                    id: 1,
                    href: '/marketplace',
                    text: 'Explore',
                },
                {
                    id: 2,
                    href: `/user/${userData?.username}`,
                    text: 'Your Profile',
                },
                {
                    id: 3,
                    href: `/create`,
                    text: 'Create your NFTs',
                },
            ],
        },
        {
            id: 2,
            title: 'Company',
            diffClass: '',
            list: [
                {
                    id: 1,
                    href: '/about',
                    text: 'Meet Our Team',
                },
                {
                    id: 2,
                    href: '/contact',
                    text: 'Contact Us',
                },
                {
                    id: 3,
                    href: '/help_center',
                    text: 'Help Center',
                },
                {
                    id: 4,
                    href: '/help_center',
                    text: 'Join our Discord channel',
                },
            ],
        },
        {
            id: 4,
            title: 'Projects',
            diffClass: '',
            list: [
                {
                    id: 1,
                    href: 'https://www.youtube.com/watch?v=uN5Nede4YZE&list=PLSu5Z8u6bvKLWUGZe9sG5IeWQ11NQRd16',
                    text: 'Art Tech Fusion 2023',
                },
            ],
        },
        {
            id: 5,
            title: 'Campains',
            diffClass: '',
            list: [
                {
                    id: 1,
                    href: 'https://icoholder.com/en/interview/empowering-artists-globally-a-nft-worlds-innovative-approach-to-inclusive-nft-marketplaces',
                    text: 'ICO Interview ',
                },
                {
                    id: 2,
                    href: 'https://icoholder.com/en/a-nft-world-1071376',
                    text: 'ICO Project Presentation',
                },
                {
                    id: 3,
                    href: 'https://forms.office.com/Pages/ResponsePage.aspx?id=zG8U6vUZs0ajGCV4dOtk5BGCCLdpPIJHsU3ROtLCro9UQ0tBWDI3UFc3NDlHQ0xHQktZVFRMQ0YzVy4u',
                    text: 'Artist Open Call Registration',
                },
            ],
        },
    ];
    return (
        <>
            <footer className="dark:bg-jacarta-900 bg-white page-footer">
                <div className="container mt-12 p-4 w-full max-w-[90%]">
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                        {/* Logo and Description */}
                        <div className="pr-4">
                            <Link href="#" className="inline-block mb-6">
                                <Image
                                    width="80"
                                    height="80"
                                    src={Logo}
                                    alt="A-NFT | NFT Marketplace"
                                    className="w-[80px] h-[80px] mb-6"
                                />
                                <p className="dark:text-jacarta-300"> A-NFT World S.R.L.</p>
                                <p className="dark:text-jacarta-300">Reg Nr : 49632450</p>
                                <p className="dark:text-jacarta-300">EUID : J25/111/2024</p>
                                <p className="dark:text-jacarta-300">Country : Romania</p>
                            </Link>
                            <p className="dark:text-jacarta-300 mb-12">
                                Create, sell and collect truly rare digital artworks. Powered by Network 3.0.
                            </p>

                            {/* Social Icons */}
                            <div className="flex space-x-5">
                                {socialIcons.map((item) => {
                                    const { id, href, icon } = item;
                                    return (
                                        <Link
                                            href={href}
                                            key={id}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="group cursor-pointer"
                                        >
                                            {icon}
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Footer Menu List */}
                        {/* Dynamically generate the remaining columns for menu items */}
                        {footerMenuList
                            .filter((menuListItem) => !menuListItem.hidden)
                            .map((single, index) => (
                                <div
                                    className={`flex flex-col md:mt-[30px] lg:text-end md:col-span-1 ${
                                        index > 0 ? 'md:ml-auto' : ''
                                    } ${single.diffClass}`}
                                    key={single.id}
                                >
                                    <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                                        {single.title}
                                    </h3>
                                    <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                                        {single.list.map((item) => {
                                            const { id, href, text } = item;
                                            return (
                                                <li key={id}>
                                                    <Link
                                                        href={href}
                                                        className="hover:text-accent dark:hover:text-white"
                                                    >
                                                        {text}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ))}
                    </div>

                    {/* Footer Bottom */}
                    <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
                        <span className="dark:text-white text-base">
                            © {new Date().getFullYear()} A-NFT World S.R.L. ver. Alpha 1.0.0
                        </span>
                        {/* ====Bottom zone, items moved to the main menu, will be revised after version 1.1.0======= */}
                        <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
                            <li>
                                <Link href="/terms" className="hover:text-accent dark:hover:text-white">
                                    Terms and conditions
                                </Link>
                            </li>
                            <li>
                                <Link href="/privacy" className="hover:text-accent dark:hover:text-white">
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                        {/* ========= */}
                    </div>
                </div>
            </footer>
        </>
    );
};
